import React, { useState } from "react";
import Terrible from "../../asset/img/Icons/terrible.gif";
import Okay from "../../asset/img/Icons/okay-2.gif";
import Good from "../../asset/img/Icons/Good.gif";
import Amazing from "../../asset/img/Icons/Amazing.gif";
import { useCartContext } from "../Context/UserContext";

const FeedbackForm = ({ onSubmit }) => {
  // const { feedbackOpen } = useCartContext();

  const feedbackOptions = [
    { title: "Terrible", img: Terrible },
    { title: "Okay", img: Okay },
    { title: "Good", img: Good },
    { title: "Amazing", img: Amazing },
  ];

  const [rating, setRating] = useState("");
  console.log(rating);
  const [thoughts, setThoughts] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const feedbackData = { rating, thoughts };

    console.log("Submitting feedback: ", feedbackData);

    onSubmit(); // Trigger the image display after submission

    setRating("");
    setThoughts("");
  };

  // Check if feedback is already present in the cart

  return (
    <div className="container">
      <div className="feedback-container p-4 background-color-eggshell ">
        <h2 className="fs-5 fw-bold">GIVE YOUR VIEW</h2>
        <p className="mt-1 text-color-pullman-green">
          What do you think about GAUSWARN A2 GIR COW GHEE?
        </p>

        <div className="emoji-rating d-flex justify-content-between">
          {feedbackOptions.map((option, index) => (
            <div
              key={index}
              className={`emoji-option ${
                rating === option.title ? "selected" : ""
              }`}
              onClick={() => setRating(option.title)}
              style={{ cursor: "pointer", textAlign: "center" }}
            >
              <img
                src={option.img}
                alt={option.title}
                style={{ width: "50px", height: "50px" }}
              />
              <p>{option.title}</p>
            </div>
          ))}
        </div>

        <form onSubmit={handleSubmit}>
          <label htmlFor="thoughts" className="mt-3">
            Your Thoughts
          </label>
          <textarea
            id="thoughts"
            value={thoughts}
            onChange={(e) => setThoughts(e.target.value)}
            placeholder="Share your feedback..."
            required
            className="mt-2 feedback-input"
          />  

          <div className="buttons mt-3">
            <button type="submit" className="submit-btn me-2">
              Submit
            </button>
            <button
              type="button"
              className="cancel-btn background-color-eggshell"
              onClick={() => {
                setRating("");
                setThoughts("");
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackForm;
