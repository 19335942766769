import React from "react";
import Home from "./Home";
import AboutUs from "./About";
import Processed from "./Processed";
import GheeComparison from "./GheeComparison";
import WhatSetsUsApart from "./WhatSetsUsApart";
import Client from "./Client";
import Certified from "./Certified";
import Footer from "../Common/Footer";
import GheeLabel from "./GheeLabel";
import GirCowInfo from "./GirCowInfo";
import CustomNavbar from "../Common/Navbar";
import Benefit from "./Gheebenefit";

const Main = () => {
  return (
    <div>
      <Home />
      <AboutUs />
      <GirCowInfo />
      {/* <GheeLabel /> */}
      <Processed />
      <Benefit/>
      <GheeComparison />
      <WhatSetsUsApart />
      <Client />
      <Certified /> 
    </div>
  );
};

export default Main;
