import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import Image1 from "../../asset/img/benefits of ghee/benefit 1.png";
import Image2 from "../../asset/img/benefits of ghee/benefit 2.png";
import Image3 from "../../asset/img/benefits of ghee/benefit 3.png";
import Image4 from "../../asset/img/benefits of ghee/benefit 4.png";
import Image5 from "../../asset/img/benefits of ghee/benefit 5.png";
import Image6 from "../../asset/img/benefits of ghee/benefit 6.png";
import Image7 from "../../asset/img/benefits of ghee/benefit 7.png";
import Image8 from "../../asset/img/benefits of ghee/benefit 8.png";
import Image9 from "../../asset/img/benefits of ghee/benefit 9.png";
import Image10 from "../../asset/img/benefits of ghee/benefit 10.png";
import Image11 from "../../asset/img/benefits of ghee/benefit 11.png";
import Image12 from "../../asset/img/benefits of ghee/benefit 12.png";

const CutoutSlider = () => {
  // Array of images
  const images = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
  ];

  // State to control visibility of each card
  const [showCards, setShowCards] = useState(Array(12).fill(false)); // Array to manage visibility of 12 cards

  console.log("Slider", showCards);
  useEffect(() => {
    // Function to start the display sequence
    const startSequence = () => {
      const sequenceTimers = [];
      console.log("Sequence", sequenceTimers);
      const cardCount = showCards.length;

      // Show each cutout card in sequence with delay
      // for (let i = 0; i < cardCount; i++) {
      //   sequenceTimers.push(
      //     setTimeout(() => {
      //       setShowCards((prev) => {
      //         const newCards = [...prev];

      //         newCards[i] = true;
      //         return newCards;
      //       });
      //       // As soon as five images are stored in previous, make it false.
      //       // Solution : -
           
            
      //       if (i % 5 === 4) {
      //         setShowCards((prev) => {
      //           const newCards = [...prev];

      //           newCards.fill(false);
      //           return newCards;
      //         });
      //       }
            

      //     }, i * 2000) 
      //   );
      // }

      for (let i = 0; i < cardCount; i++) {
        sequenceTimers.push(
          setTimeout(() => {
            setShowCards((prev) => {
              const newCards = [...prev];
              newCards[i] = true; // Show current image
              return newCards;
            });
      
            // Check if we've reached the end of a group of 5 images or the end of the list
            if ((i + 1) % 5 === 4 || i === cardCount - 1) {
              // Set a delay to hide all images after 2 seconds
              setTimeout(() => {
                setShowCards(Array(cardCount).fill(false)); // Hide all images
              }, 2000);
            }
          }, i * 2000) // Show each image with a 2-second delay
        );
      }

      // Hide all cards after showing them
      const hideAllTimer = setTimeout(() => {
        setShowCards(Array(cardCount).fill(false)); // Hide all cards
        startSequence(); // Restart sequence
      }, cardCount * 2000 + 2000); // Hide after all cards are shown

      // Clear timers on component unmount
      return () => {
        sequenceTimers.forEach((timer) => clearTimeout(timer));
        clearTimeout(hideAllTimer);
      };
    };

    // Start the sequence when component mounts
    startSequence();
  }, []);

  return (
    <div className="benefit py-5">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-3 py-5">
            <div className="benefit-logo">
              <img
                src={Image}
                alt="GAUSWARN A2 Ghee Logo"
                className="w-75 h-100"
              />
            </div>
          </div> */}
          <div className="col-lg-12 pb-5">
            <div className="benefit-heading">
              <h1 className="text-color-pullman-green text-center">
                Benefits of A2 Gir Cow Ghee
              </h1>
            </div>
          </div>
          {/* Conditional rendering for each cutout */}
          {showCards.map((showCard, index) => (
            <div
              className={`col-md-3 col-sm-6 pb-4 ${
                showCard ? "slide-in d-flex justify-content-center align-items-center" : "d-none"
              }`}
              key={index}
            >
              <img
                src={images[index]}
                className="w-75"
                alt={`Cutout ${index + 1}`}
              />
              {/* <div className="card-body">
                <h5 className="card-title">{`Cutout ${index + 1}`}</h5>
                <p className="card-text">This is cutout number {index + 1}.</p>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CutoutSlider;
