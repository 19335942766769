import React, { useState } from "react";
import Image1 from "../../asset/img/Processed/product.png";
import Image2 from "../../asset/img/Processed/product.png";
import Image3 from "../../asset/img/Processed/product.png";
import Image4 from "../../asset/img/Processed/product.png";
import Image5 from "../../asset/img/Processed/product.png";
import Image6 from "../../asset/img/Processed/product.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useCartContext } from "../Context/UserContext";

const Order = () => {

  const data = [
    {
      liter: "500 ml",
      batch: <b>SKU: 1425006</b>,
      price: 715.0,
    },
    {
      liter: "1 ltr",
      batch: <b>SKU: 1425006</b>,
      price: 1400.0,

      inr: "850",
    },
    {
      liter: "5 ltr",
      batch: <b>SKU: 1425006</b>,
      price: 7000.0,

      inr: "850",
    },
    {
      liter: "15 ltr",
      batch: <b>SKU: 1425006</b>,
      price: 21000.0,

      inr: "850",
    },
  ];


  const { setCart, cart } = useCartContext();

  const [selectedImage, setSelectedImage] = useState(Image1);
  const [checkedItems, setCheckedItems] = useState([
    true,
    false,
    false,
    false,
  ]);
  const [count, setCount] = useState(1);
  const [totalAmount, setTotalAmount] = useState(data[0].price.toFixed(2)); // Set initial price
  // const [cart, setCart] = useState([]); // Cart state to manage cart items
  const [oldPrice, setOldPrice] = useState((data[0].price * 1.2).toFixed(2)); // Example: 20% higher than base price

  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  
  const handleImageClick = (image) => setSelectedImage(image);

  const handleCheckboxChange = (index) => {
    const newCheckedItems = checkedItems.map((_, idx) => idx === index);
    setCheckedItems(newCheckedItems);
    setOldPrice((data[index].price * 1.2).toFixed(2)); // 20% higher than base price
    setTotalAmount((data[index].price * count).toFixed(2)); // Set total based on selected variant
  };

  const increaseCount = () => {
    setCount(count + 1);
    const selectedIndex = checkedItems.findIndex((item) => item);
    if (selectedIndex !== -1) {
      setTotalAmount((data[selectedIndex].price * (count + 1)).toFixed(2));
    }
  };

  const handleAddToCart = () => {
    const selectedIndex = checkedItems.findIndex((item) => item);
    if (selectedIndex !== -1 && count > 0) {
      const selectedItem = data[selectedIndex];
      const existingItemIndex = cart.findIndex(
        (cartItem) => cartItem.liter === selectedItem.liter
      );

      if (existingItemIndex !== -1) {
        // Update quantity if item is already in cart
        const updatedCart = cart.map((cartItem, index) =>
          index === existingItemIndex
            ? { ...cartItem, quantity: cartItem.quantity + count }
            : cartItem
        );
        setCart(updatedCart);
      } else {
        // Add new item to cart
        setCart([
          ...cart,
          { ...selectedItem, quantity: count, img: selectedImage },
        ]);
      }

      setTotalAmount("");
      setCount(0);
    }
  };

  const decreaseCount = () => {
    if (count > 1) {
      setCount(count - 1);
      // Update total amount whenever quantity changes
      const selectedIndex = checkedItems.findIndex((item) => item);
      if (selectedIndex !== -1) {
        setTotalAmount((data[selectedIndex].price * (count - 1)).toFixed(2));
      }
    }
  };

  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 7,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    arrows: false,
    responsive: [
      { breakpoint: 1026, settings: { slidesToShow: 4 } },
      { breakpoint: 998, settings: { slidesToShow: 3, vertical: false } },
      { breakpoint: 768, settings: { slidesToShow: 3, vertical: false } },
      { breakpoint: 480, settings: { slidesToShow: 2, vertical: false } },
      { breakpoint: 360, settings: { slidesToShow: 1, vertical: false } },
    ],
  };

  return (
    <>
      <div className="order pt-5 pb-5">
        <div className="container pt-2 pb-2">
          <div className="row">
            <div className="col-lg-1 col-md-12 col-sm-12">
              <div className="scrollbar-container">
                <Slider {...settings}>
                  {images.map((image, index) => (
                    <div
                      key={index}
                      className="select-order-img py-1 d-flex justify-content-center"
                    >
                      <img
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        onClick={() => handleImageClick(image)}
                        className="w-100"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="order-img border rounded-5">
                <img src={selectedImage} alt="Selected" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="order-section">
                <h3 className="text-success">
                  Certified Organic A2 Desi Gir Cow Ghee
                </h3>
                <p className="mt-3">Cultured with vedic bilona method</p>
                <p className="fs-4">
                  <b className="text-warning">★ ★ ★ ★ ★</b> <b>4.9</b>
                </p>
                <div className="fw-bold">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/color/48/vegetarian-food-symbol.png"
                    alt="vegetarian-food-symbol"
                  />

                  <span className="ms-2">This is a Vegetarian Product</span>
                </div>

                {/* <p className="lh-lg">
                  <b>Rs.{totalAmount}</b> MRP (Incl. of all taxes)
                </p> */}

                <p className="lh-lg">
                  <span
                    style={{ textDecoration: "line-through", color: "grey" }}
                  >
                    Rs. {oldPrice}
                  </span>{" "}
                  <b>Rs. {totalAmount || "—"}</b> MRP (Incl. of all taxes)
                </p>

                <p className="w-100 my-2 border border-secondary"></p>
                <p className="fw-bold pt-4">Select Variant</p>
                <div className="card-flex pt-2 d-flex align-items-center gap-2 w-100 h-100">
                  {data.map((item, index) => (
                    <div
                      key={index}
                      className={`order-card ${
                        checkedItems[index] ? "highlight" : ""
                      }`}
                      onClick={() => handleCheckboxChange(index)}
                    >
                      <div className="order-card-head  p-1 d-flex justify-content-between" style={{borderRadius:"10px 10px 0px 0px"}}>
                        <p className="fw-bold text-light mt-2 px-3">
                          {item.liter}
                        </p>
                        <div className="form-check">
                          <input
                            className="form-check-input border-0"
                            type="checkbox"
                            checked={checkedItems[index]}
                            onChange={() => handleCheckboxChange(index)}
                            id={`flexCheckDefault${index}`}
                            // style={{ backgroundColor: "#352b0d" }}
                          />
                        </div>
                      </div>
                      <div className="order-card-price border ps-3 pt-3" style={{borderRadius:"0px 0px 10px 10px"}}>
                        <p>{item.batch}</p>
                        <h6 className="mb-3">
                          <b>INR {item.price} /-</b>
                        </h6>
                        {/* <h6>{item.price}</h6> */}
                        <p className="">(Inc of All Taxes)</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="count mt-4 d-flex align-items-center">
                  {/* <p className="me-2 mt-2">Quantity:</p> */}
                  <div className="count-btn d-flex align-items-center justify-content-center border border-3 border-black rounded-pill">
                    <button
                      onClick={decreaseCount}
                      className="count-section border-0 bg-transparent"
                    >
                      <BiSolidDownArrow />
                    </button>
                    <div className="count-section border-0 fw-bold">
                      {count}
                    </div>
                    <button
                      onClick={increaseCount}
                      className="count-section border-0 bg-transparent"
                    >
                      <BiSolidUpArrow />
                    </button>
                  </div>
                </div>

                <div className="cart-order d-flex mt-4 cursor-pointer">
                  <button
                    className="cart-btn px-1 text-uppercase text-light d-flex align-items-center justify-content-between border rounded-pill"
                    onClick={handleAddToCart}
                  >
                    <div className="cart-icon rounded-pill d-flex align-items-center justify-content-center fs-5">
                      <HiOutlineShoppingCart />
                    </div>
                    <p className="w-100 text-uppercase pt-3 lh-1">
                      add to cart
                    </p>
                  </button>

                  <Link
                    to="/finalpayment"
                    className="cart-btn text-decoration-none ms-5 px-1 text-uppercase text-light d-flex align-items-center justify-content-between border rounded-pill"
                  >
                    <div className="cart-icon rounded-pill d-flex align-items-center justify-content-center fs-5">
                      <HiOutlineShoppingCart />
                    </div>
                    <p className="m-auto text-uppercase lh-1">BUY NOW</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
