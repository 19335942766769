import React from "react";
import ManImage from "../../asset/img/Icons/man.png"; // Replace with actual image path
import GheeImage from "../../asset/img/Ghee/ds (6).png"; // Replace with actual image path
import PurityIcon from "../../asset/img/Icons/about-img-icon.png"; // Replace with actual icon path
import HealthIcon from "../../asset/img/Icons/about-img-2.png"; // Replace with actual icon path

const AboutUs = () => {
  return (
    <section className="p-4 background-color-pullman-green">
      <div className="d-flex align-items-center background-color-white py-5">
        <div className="container">
          <div className="row ">
            <div
              className="col-lg-6 col-sm-12 d-flex justify-content-center"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <img src={ManImage} alt="Man" className="img-fluid py-4" />
            </div>
            <div
              className="col-lg-6 col-sm-12 d-flex align-items-center"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <div>
                <div className="p-3">
                <h2 className="text-color-pullman-green fw-bold">ABOUT US</h2>

                  <div className="text-color-green font-size-18 fw-bold mt-1">
                    About our A2 Ghee
                  </div>
                  <h2 className="text-color-pullman-green fw-bold mt-2">
                    {" "}
                    Nourish Your Body,
                    <br /> Elevate Your Dishes
                  </h2>

                  <p className="">
                    Ghee is a revolutionary, sustainable, and eco-friendly
                    beverage that brings a fresh and healthy taste to the table.
                    Our mission is to make ghee accessible and affordable to
                    everyone, regardless of their dietary restrictions or
                    lifestyle.
                  </p>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 d-flex align-items-center py-2">
                      <div>
                        <img
                          src={HealthIcon}
                          alt="Purity Icon"
                          className="icon-img"
                        />
                      </div>

                      <div className="text-color-pullman-green font-size-18 fw-bold ms-3">
                        We prioritize Purity
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex align-items-center py-2">
                      <div>
                        <img
                          src={PurityIcon}
                          alt="Purity Icon"
                          className="icon-img"
                        />
                      </div>

                      <div className="text-color-pullman-green font-size-18 fw-bold m-3">
                        We prioritize Purity
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
