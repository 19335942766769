// import React from "react";
// import { Navbar, Nav, Button } from "react-bootstrap";
// import logo from "../../../asset/img/FinalPay/GAUSWARN A2 GHEE.png";
// import { GrHomeRounded } from "react-icons/gr";
// import { FiShoppingCart } from "react-icons/fi";
// import { NavLink } from "react-router-dom";
// import { useCartContext } from "../../Context/UserContext";

// const CustomNavbar = () => {
//   const { cart, } = useCartContext();

//   let size = cart.length;

//   return (
//     <>
//       <Navbar expand="lg" className="custom-navbar">
//         <div className="container">
//           <Navbar.Brand href="#">
//             <img src={logo} alt="Logo" />
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="ms-auto">
//               <NavLink to="/" className="nav-link mx-3">
//                 <GrHomeRounded />
//               </NavLink>
//               <Nav.Link href="../../Pages/About.jsx" className="nav-link mx-3">
//                 Product List
//               </Nav.Link>
//               <Nav.Link href="#" className="nav-link mx-3">
//                 About Us
//               </Nav.Link>
//               <Nav.Link href="#" className="nav-link mx-3">
//                 Blogs
//               </Nav.Link>
//             </Nav>
//             <NavLink to="/contact">
//               <Button className="contact-btn mx-3">Contact Us</Button>
//             </NavLink>
//             {/* <NavLink to="/" className="nav-link mx-3">
//               <FiShoppingCart className="fs-5"/>
//             </NavLink> */}
//             <div className="nav-item cart-trolley-link">
//                 <NavLink to="/finalpayment" className="nav-link">
//                   <FiShoppingCart className="cart-trolley" />
//                   {size === 0 ? (
//                     <>
//                       <span className="cart-total-item"></span>
//                     </>
//                   ) : (
//                     <>
//                       <span className="cart-total">{size}</span>
//                     </>
//                   )}
//                 </NavLink>
//               </div>
//           </Navbar.Collapse>
//         </div>
//       </Navbar>
//     </>
//   );
// };

// export default CustomNavbar;

import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import logo from "../../../asset/img/FinalPay/GAUSWARN A2 GHEE.png"; // Replace with your logo path
import { GrHomeRounded } from "react-icons/gr";
import { useCartContext } from "../../Context/UserContext";
import { FiShoppingCart } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const CustomNavbar = () => {
  const { cart } = useCartContext();

  let size = cart.length;

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY >= 50); // Activate only when scrolled 100px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Navbar
      expand="lg"
      className={`custom-navbar ${isFixed ? "scrolled" : ""}`}
    >
      <div className="container">
        <Navbar.Brand href="#">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink to="/" className="nav-link mx-3">
              <GrHomeRounded className="fs-5"/>
            </NavLink>
            <Nav.Link href="#" className="nav-link mx-3">
              Product List
            </Nav.Link>
            <Nav.Link href="#" className="nav-link mx-3">
              About Us
            </Nav.Link>
            <Nav.Link href="#" className="nav-link mx-3">
              Blogs
            </Nav.Link>
          </Nav>
          <NavLink to="/contact">
            <Button className="contact-btn mx-3">Contact Us</Button>
          </NavLink>
          <div className="nav-item cart-trolley-link">
            <NavLink to="/finalpayment" className="nav-link">
              <FiShoppingCart className="fs-5" />
              {size === 0 ? (
                <>
                  <span className="cart-total-item"></span>
                </>
              ) : (
                <>
                  <span className="cart-total">{size}</span>
                </>
              )}
            </NavLink>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default CustomNavbar;
