import React from "react";
import { useForm } from "react-hook-form";
import Image from "../../asset/img/FinalPay/GAUSWARN A2 GHEE.png";
import Image2 from "../../asset/img/FinalPay/3.png";
import EmptyCart from "../../asset/img/Order/cart.png";
import { useCartContext } from "../Context/UserContext";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import Button from "../Common/Button";

const Finalpayment = () => {
  const { cart, setCart } = useCartContext();

  let size = cart.length;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const subtotal = cart.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const shipping = 100; // Fixed or calculate as per your logic
  const total = subtotal + shipping;

  // removeFromCart
  const removeFromCart = (id) => {
    const updatedCart = cart.filter((item, index) => index !== id); // Use index if items do not have unique IDs
    setCart(updatedCart);
  };

  return (
    <>
      <div className="final-pay">
        <div className="container-fluid">
          {size === 0 ? (
            <div className="py-5">
              <div>
                <h3 className="text-center">- : Your Basket is Empty : -</h3>
              </div>
              <div className="d-flex justify-content-center my-5">
                <h5>
                  {/* <FontAwesomeIcon icon={faCartShopping} /> */}
                  <img
                    src={EmptyCart}
                    alt="Loading"
                    className="rounded mx-auto d-block w-75"
                  />
                </h5>
              </div>
              <Link to="/singleproduct" className="text-center d-block mt-3">
                {/* <button className="btn btn-primary">Go Back to Shopping</button> */}
                <Button title="Go Back to Shopping"/>
              </Link>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-lg-6 col-md-12 py-5 background-color-pullman-green">
                  <div className="order-input px-4">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="p-4 border rounded-3 lh-lg"
                      style={{
                        maxWidth: "600px",
                        margin: "0 auto",
                      }}
                    >
                      <label className="text-white fw-bold">CONTACT</label>
                      <input
                        type="email"
                        className="input w-100 px-3 py-1 border rounded-3"
                        {...register("email", { required: true })}
                        placeholder="E-MAIL"
                      />
                      {errors.email && <p>This field is required</p>}
                      <br />
                      <br />

                      <label className="text-white fw-bold">DELIVERY</label>
                      <input
                        className="w-100 px-3 py-1 border rounded-3"
                        {...register("country", { required: true })}
                        placeholder="COUNTRY"
                      />
                      {errors.country && <p>This field is required</p>}

                      <input
                        className="w-100 mt-2 px-3 py-1 border rounded-3"
                        {...register("fullName", { required: true })}
                        placeholder="FULL NAME"
                      />
                      {errors.fullName && <p>This field is required</p>}

                      <input
                        className="w-100 mt-2 px-3 py-1 border rounded-3"
                        {...register("houseNoColony", { required: true })}
                        placeholder="HOUSE NO. / COLONY"
                      />
                      {errors.houseNoColony && <p>This field is required</p>}

                      <input
                        className="w-100 mt-2 px-3 py-1 border rounded-3"
                        {...register("streetLandmark", { required: true })}
                        placeholder="STREET / LANDMARK"
                      />
                      {errors.streetLandmark && <p>This field is required</p>}
                      <div className="d-flex justify-content-between flex-wrap">
                        <input
                          className="city-input mt-2 px-3 py-1 border rounded-3"
                          {...register("city", { required: true })}
                          placeholder="CITY"
                        />
                        {errors.city && <p>This field is required</p>}

                        <input
                          className="state-input mt-2 px-3 py-1 border rounded-3"
                          {...register("state", { required: true })}
                          placeholder="STATE"
                        />
                        {errors.state && <p>This field is required</p>}
                      </div>

                      <div className="d-flex justify-content-between flex-wrap">
                        <input
                          className="pin-input mt-2 px-3 py-1 border rounded-3"
                          type="number"
                          {...register("pin", { required: true })}
                          placeholder="PIN"
                        />
                        {errors.pin && <p>This field is required</p>}

                        <input
                          className="mob-input mt-2 px-3 py-1 border rounded-3"
                          type="tel"
                          {...register("mobileNo", { required: true })}
                          placeholder="MOB NO."
                        />
                        {errors.mobileNo && <p>This field is required</p>}
                      </div>

                      <label className="text-white mt-3 w-100">
                        <b>PAYMENT</b> secure and encrypted payment
                      </label>
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label text-white ms-2"
                          htmlFor="flexRadioDefault1"
                        >
                          Razorpay Secure (UPI, Cards, Wallet, NetBanking)
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                        />
                        <label
                          className="form-check-label text-white ms-2"
                          htmlFor="flexRadioDefault2"
                        >
                          Cash On Delivery
                        </label>
                      </div>

                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          type="submit"
                          className="w-50 border rounded-pill text-uppercase button-feedback"
                        >
                          Pay Now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="final-price">
                    <div className="order-logo m-auto">
                      <img src={Image} alt="Product" className="w-100 h-100" />
                    </div>

                    <div className="product-description mt-5">
                      {cart.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-between align-items-center py-2"
                        >
                          <div className="product-img">
                            <img src={item.image || Image2} alt="Product" />
                          </div>
                          <div className="product-content lh-1">
                            <p className="fw-bold">
                              Pure Traditional Organic A2 Gir Cow Ghee
                              <span className="ps-3 fw-bolder">
                                {item.quantity} x
                              </span>
                              <span className="fw-normal ms-5">
                                ₹ {item.price}
                              </span>
                            </p>
                          </div>
                          <div className="fs-3 mb-3 me-3" onClick={() => removeFromCart(index)}>
                            
                            <MdDelete style={{color:"red"}}/>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <p className="fs-5 ms-4">
                        Subtotal - <b>{cart.length} items</b>
                      </p>
                      <p className="fw-normal me-4">₹{subtotal}</p>
                    </div>
                    <div className="d-flex justify-content-between lh-1">
                      <p className="fs-5 ms-4">Shipping Charges</p>
                      <p className="fw-normal me-4">₹{shipping}</p>
                    </div>
                    <div className="d-flex justify-content-between lh-1">
                      <p className="fs-5 fw-bold ms-4">Total</p>
                      <p className="fw-normal me-4">₹{total}</p>
                    </div>
                    <p className="lh-1 ms-4">(including all services and taxes)</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Finalpayment;
