import "./App.css";

import Main from "./Component/Pages/Main";
import { Routes, Route } from "react-router-dom";
import SingleProcess from "./Component/Pages/SingleProcessed.jsx";
import Finalpayment from "./Component/Pages/Finalpayment.jsx";
import CustomNavbar from "./Component/Common/Navbar/index.jsx";
import Footer from "./Component/Common/Footer/index.jsx";
import ScrollToTop from "./Component/Common/Scroll-to-Top/index.jsx";
import Contact from "./Component/Pages/Contact.jsx";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 100, // Offset to the element when scrolling (in pixels)
      duration: 1000, // Animation duration in milliseconds
      easing: "ease-in-out", // Easing pattern (see https://easings.io/)
      once: false, // Whether animation should happen only once
      mirror: true, // Mirror the animation when element is above the viewport (true or false)
      anchorPlacement: "top-bottom", // Placement of the element (top, bottom, left, right, top-left, top-right, bottom-left, bottom-right)
    });
  }, []);

  return (
    <>
      <ScrollToTop />
      <CustomNavbar />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/singleproduct" element={<SingleProcess />} />
        <Route path="/finalpayment" element={<Finalpayment />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
