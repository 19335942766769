import React from "react";

// Image imports
import Logo from "../../../asset/img/Logo/RAJLAXMI JAVIK PNG.png";
import {

  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaEnvelope,
  FaWhatsapp,
} from "react-icons/fa";
import FooterTopImg from "../../../asset/img/Background/footer-img-top.png";
import { MdOutlineMail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";

// Data for map
const footerLinks = [
  {
    title: "Navigate Our Site",
    links: ["Home", , "About Us", "Shop Now", "Contact Us"],
  },
  // {
  //   title: "Contact Us",
  //   links: ["Address: 11 Manish Baag Sapna Sangeeta Road Indore Madhya Pradesh  452001", "rajlaxmiorganicfoods@gmail.com", "+91 8769115905"],
  // },
];

const Footer = () => {
  return (
    <footer className="pt-5">
      <div className="">
        <img className="img-fluid w-100" src={FooterTopImg} alt="Background" />
      </div>
      <div className="background-color-pullman-green py-2">
        <div className="container">
          <div className="row">
            {/* Logo */}
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              <img
                className="img-fluid"
                src={Logo}
                alt="Logo"
                style={{ maxWidth: "100px" }}
              />
            </div>

            {/* Social Icons */}
            <div className="col-md-6 d-flex justify-content-md-end justify-content-center align-items-center">
              {[
                { Icon: FaFacebook, alt: "Facebook" },
                { Icon: FaInstagram, alt: "Instagram" },
                { Icon: FaYoutube, alt: "YouTube" },
                { Icon: FaEnvelope, alt: "Email" },
                { Icon: FaWhatsapp, alt: "WhatsApp" },
              ].map((icon, index) => (
                <a key={index} href="#/" className="mx-2">
                  <icon.Icon
                    className="icon-size rounded-circle text-color-eggshell"
                    style={{ width: "35px", height: "35px", padding: "4px", }}
                    title={icon.alt}
                  />
                </a>
              ))}
            </div>

            <div className="col-12">
              <hr className="hr-line-background" />
            </div>

            <div className="col-lg-6 col-md-6 col-6">
              <div className="fw-bold text-color-eggshell text-center fs-5">
                About Gauswaran A2 Gir Cow Ghee
              </div>
              <p className="footer-about mx-auto text-color-eggshell pt-2">
                Rajlakshmi Javik International is committed to producing the
                highest quality Gauswaran A2 Gir Cow Ghee, crafted using the
                traditional Bilona method. Our ghee is 100% natural, free from
                additives and preservatives, and made with love and care to
                offer a healthy, nutrient-rich product for your kitchen and
                well-being.
              </p>
            </div>

            {/* Footer Links */}
            {footerLinks.map((section, index) => (
              <div className="col-lg-3 col-md-6 col-6 text" key={index}>
                <div className="fw-bold text-color-eggshell">{section.title}</div>
                <div className="border-color mb-3"></div>
                <ul
                  className="list-unstyled"
                  style={{ listStyleType: "none", paddingLeft: "0px" }}
                >
                  {section.links.map((link, linkIndex) => (
                    <li className="my-2" key={linkIndex}>
                      <a
                        href="#/"
                        className="text-decoration-none text-color-eggshell"
                      >
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div className="col-lg-3 col-md-6 col-6 text">
              <div className="fw-bold text-color-eggshell">Contact Us</div>
              <div className="border-color mb-3"></div>
              <ul
                className="list-unstyled"
                style={{ listStyleType: "none", paddingLeft: "0px" }}
              >
                <li className="my-2">
                  <a
                    href="#/"
                    className="text-decoration-none text-color-eggshell"
                  >
                    Address: 11 Manish Baag Sapna Sangeeta Road Indore Madhya
                    Pradesh 452001
                  </a>
                </li>
                <li className="my-2">
                  <a
                    href="#/"
                    className="text-decoration-none text-color-eggshell"
                  >
                    <MdOutlineMail /> rajlaxmiorganicfoods@gmail.com
                  </a>
                </li>
                <li className="my-2">
                  <a
                    href="#/"
                    className="text-decoration-none text-color-eggshell"
                  >
                    <IoMdCall /> +91 8769115905
                  </a>
                </li>
              </ul>
            </div>

            {/* Subscribe */}

            {/* <div className="col-12 col-md-6 col-lg-3 d-flex justify-content-center justify-content-md-start">
              <div className="w-100">
                <div className="fw-bold text-color-white">Subscribe</div>
                <div className="border-color mb-2"></div>
                <form>
                  <input
                    type="email"
                    className="form-control input-background-color mb-2"
                    placeholder="Enter Your Email"
                  />
                  <button className="btn btn-background-color fw-bold w-100 mt-2">
                    Subscribe
                  </button>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* Bottom Footer */}

      <div className="background-color-eggshell py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start mb-2 mb-md-0">
              <div className="text-color-pullman-green">
                Copyright &copy; 2024 Rajlakshmi Jaiviks International.{" "}
                <b>All Rights Reserved</b>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              <a
                href="#/"
                className="mx-2 text-decoration-none text-color-pullman-green"
              >
                Privacy Policy
              </a>
              <a
                href="#/"
                className="mx-2 text-decoration-none text-color-pullman-green"
              >
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
