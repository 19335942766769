import React, { useRef, useState } from "react";
import Avatar from "../../asset/img/Icons/man.png";
import Pen from "../../asset/img/Icons/Untitled design.svg";
import ArrowIcon from "../../asset/img/Icons/arrow-1.png";
import ClientTopImg from "../../asset/img/Background/Client-img-top.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { LuPointer } from "react-icons/lu";
import { useCartContext } from "../Context/UserContext";
import FeedbackForm from "./Feedback";
import UploadedImage from "../../asset/img/Icons/clapping.gif";
import Button from "../Common/Button/index"

const testimonials = [
  {
    id: 1,
    text: "A2 ghee has become a staple in my cooking. The quality is fantastic, and I love the nutty aroma when I melt it. It makes my Indian dishes taste authentic, and I even use it in baking for an extra depth of flavor. I also appreciate that it's organic and free from additives. Will definitely buy again!",
    rating: 5,
    avatar: Avatar,
  },
  {
    id: 2,
    text: "A2 ghee has become a staple in my cooking. The quality is fantastic, and I love the nutty aroma when I melt it. It makes my Indian dishes taste authentic, and I even use it in baking for an extra depth of flavor. I also appreciate that it's organic and free from additives. Will definitely buy again!The best ghee I've ever tasted! It adds a wonderful flavor to everything I cook.",
    rating: 5,
    avatar: Avatar,
  },
  {
    id: 3,
    text: "A2 ghee has become a staple in my cooking. The quality is fantastic, and I love the nutty aroma when I melt it. It makes my Indian dishes taste authentic, and I even use it in baking for an extra depth of flavor. I also appreciate that it's organic and free from additives. Will definitely buy again! I love using A2 ghee for my morning toast. It melts beautifully and tastes amazing.",
    rating: 4,
    avatar: Avatar,
  },
  {
    id: 4,
    text: "Great quality and a fantastic price. Will be purchasing again soon!",
    rating: 5,
    avatar: Avatar,
  },
  {
    id: 5,
    text: "A must-have in every kitchen! It enhances the flavor of my dishes.",
    rating: 5,
    avatar: Avatar,
  },
];
const Client = () => {
  const { feedbackOpen, setFeedbackOpen } = useCartContext();
  const [showImage, setShowImage] = useState(false); // Image display state

  const sliderRef = useRef(null);
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
  };
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  // Handle form submission to display image
  const handleFeedbackSubmit = () => {
    setShowImage(true);
    // setFeedbackOpen(false);

    // Remove image and reset after 30 seconds
    setTimeout(() => {
      setShowImage(false);
      setFeedbackOpen(false); // Show the client reviews again
    }, 3000); // 30 seconds
  };

  return (
    <>
      {showImage ? (
        <div className="uploaded-image-container text-center">
          <img
            src={UploadedImage}
            alt="Thank You Image"
            className="img-fluid"
          />
          <p>Thank you for your feedback!</p>
        </div>
      ) : feedbackOpen ? (

        <FeedbackForm onSubmit={handleFeedbackSubmit} />
      ) : (
        <>
          <div className="w-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="button-wrapper py-3"
                    onClick={() => setFeedbackOpen(true)}
                  >
                    {/* <button className="review-button">Review</button> */}
                    <Button title="Review"/>
                    <div className="review-icon">
                      <LuPointer />
                    </div>
                  </div>
                  <div className="text-center text-color-pullman-green font-size-27 font-family-roboto py-3">
                    Customer’s Review
                  </div>

                  <Slider ref={sliderRef} {...settings}>
                    {testimonials.map((item) => (
                      <div
                        className="testimonial-container w-100  d-flex align-items-center justify-content-center"
                        key={item.id}
                      >
                        <div className="testimonial-card m-auto mt-5 d-flex align-items-center justify-content-center w-75">
                          <div className="testimonial-icon">
                            <img
                              src={Pen}
                              alt="Quill Icon"
                              className="quill-icon"
                            />
                          </div>
                          <div className="testimonial-content">
                            <div className="testimonial-stars py-2">
                              {"★".repeat(item.rating)}
                              {"☆".repeat(5 - item.rating)}
                            </div>
                            <p className="testimonial-text">{item.text}</p>
                          </div>
                          <div className="testimonial-avatar">
                            <img
                              src={item.avatar}
                              alt="Avatar"
                              className="avatar-img"
                            />
                          </div>
                          <img
                            src={ArrowIcon}
                            alt="Arrow"
                            className="arrow-icon"
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="buttons d-flex align-items-center justify-content-center gap-3 ">
                  <button
                    className="border-0 bg-transparent"
                    onClick={handlePrev}
                  >
                    <FaArrowLeft />
                  </button>{" "}
                  <button
                    className="border-0 bg-transparent"
                    onClick={handleNext}
                  >
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            </div>
            <div className="py-3">
              <img
                className="img-fluid w-100"
                src={ClientTopImg}
                alt="Background"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Client;
