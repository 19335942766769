import React, { useState, useEffect } from "react";
import CustomNavbar from "../../Component/Common/Navbar";
import jarImage1 from "../../asset/img/Home/4.png";
import jarImage2 from "../../asset/img/Home/5.png";
import jarImage3 from "../../asset/img/Home/6.png";
import jarImage4 from "../../asset/img/Home/image 7.png";
import jarImage5 from "../../asset/img/Home/image 8.png";
import jarImage6 from "../../asset/img/Home/image 6.png";
import jarImage7 from "../../asset/img/Home/2.png";
import jarImage8 from "../../asset/img/Home/3.png";
import jarImage9 from "../../asset/img/Home/jar.png";
import jar from "../../asset/img/Home/jar.png";
import Button from "../Common/Button";

const Home = () => {
  // Array of image groups
  const imageGroups = [
    [jarImage1, jarImage2, jarImage3],
    [jarImage4, jarImage5, jarImage6],
    [jarImage7, jarImage8, jarImage9],
  ];

  // State to track the current group index
  const [currentGroup, setCurrentGroup] = useState(0);

  useEffect(() => {
    // Timer to rotate groups every 3 seconds
    const interval = setInterval(() => {
      setCurrentGroup((prevGroup) => (prevGroup + 1) % imageGroups.length);
    }, 3000); // Change every 3 seconds

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [imageGroups.length]);

  return (
    <div className="home">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Section */}
          <div
            className="col-lg-6 text-section"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <p className="pure-text">100% Pure</p>
            <h1>
              Make Your Dish More <br />
              <span className="highlight-text fw-bold">
                Healthy With A2 <br />
                GIR <b className="text-color-green"> Cow Ghee</b>
              </span>
            </h1>
            <p className="description-text fs-6 fw-bold">
              Lorem Ipsum Is Simply Dummy Text Of The Printing And <br />{" "}
              Typesetting Industry.
            </p>
            {/* <button className="shop-button">Shop Now</button> */}
            <Button title="Shop Now" />
          </div>

          {/* Right Section */}
          <div
            className="col-lg-6 mt-4 ps-5"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <div className="jar-container">
              {/* Rotating Images */}
              <div className="food-images">
                {imageGroups[currentGroup].map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Food ${index + 1}`}
                    className={`food-image food-${index + 1}`}
                  />
                ))}
              </div>
              <div className="jar-image">
                <img src={jar} alt="Ghee Jar" className="main-jar" />
                <div className="back-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
