import React from "react";
import Button from "../Common/Button";

const Contact = () => {
  return (
    <>
      {/* <!-- breadcrumb-section --> */}
      <div class="breadcrumb-section breadcrumb-bg">
        
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
              <div class="breadcrumb-text">
                <h1>Contact us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end breadcrumb section --> */}
      {/* <!-- contact form --> */}
      <div
        class="contact-from-section mt-150 mb-150"
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-8 mb-5 mb-lg-0">
              <div class="form-title">
                <h2>Have you any question?</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Pariatur, ratione! Laboriosam est, assumenda. Perferendis, quo
                  alias quaerat aliquid. Corporis ipsum minus voluptate? Dolore,
                  esse natus!
                </p>
              </div>
              <div id="form_status"></div>
              <div class="contact-form">
                <form type="POST" id="fruitkha-contact">
                  <p>
                    <span className="me-2">
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        id="name"
                      />
                    </span>

                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      id="email"
                    />
                  </p>
                  <p>
                    <span className="me-2">
                      <input
                        type="tel"
                        placeholder="Phone"
                        name="phone"
                        id="phone"
                      />
                    </span>
                    <input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      id="subject"
                    />
                  </p>
                  <p>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      placeholder="Message"
                    ></textarea>
                  </p>
                  <input type="hidden" name="token" value="FsWga4&@f6aw" />
                  <p>
                    {/* <input type="submit" value="Submit" /> */}
                    <Button title="Submit"/>
                  </p>
                </form>
              </div>
            </div>
            <div
              class="col-lg-4"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <div class="contact-form-wrap">
                <div class="contact-form-box">
                  <h4>
                    <i class="fas fa-map"></i> Shop Address
                  </h4>
                  <p>
                    {/* 34/8, East Hukupara <br /> Gifirtok, Sadan. <br /> Country
                    Name */}
                    11 Manish Baag Sapna Sangeeta Road Indore Madhya
                    Pradesh 452001
                  </p>
                </div>
                <div class="contact-form-box">
                  <h4>
                    <i class="far fa-clock"></i> Shop Hours
                  </h4>
                  <p>
                    MON - SAT: 10 to 8 PM <br /> SUN : CLOSE
                  </p>
                </div>
                <div class="contact-form-box">
                  <h4>
                    <i class="fas fa-address-book"></i> Contact
                  </h4>
                  <p>
                    Phone: +91 8769115905 <br /> Email: rajlaxmiorganicfoods@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end contact form --> */}
      {/* <!-- find our location --> */}
      <div class="find-location background-color-pullman-green">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-12 text-center"
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
            >
              <p>
                {" "}
                <i class="fas fa-map-marker-alt"></i> Find Our Location
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end find our location --> */}
      {/* <!-- google map section --> */}
      <div class="embed-responsive embed-responsive-21by9">
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26432.42324808999!2d-118.34398767954286!3d34.09378509738966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bf07045279bf%3A0xf67a9a6797bdfae4!2sHollywood%2C%20Los%20Angeles%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1576846473265!5m2!1sen!2sbd"
          height="450"
          frameborder="0"
          style={{ border: "0", width: "100%" }}
          allowfullscreen=""
          class="embed-responsive-item"
        ></iframe> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.756483627257!2d75.86814137476085!3d22.700107428286067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fce21e4694af%3A0xa8938712e4fc840d!2s11%2C%20Manish%20Baag%20Colony%2C%20Navlakha%2C%20Indore%2C%20Madhya%20Pradesh%20452001!5e0!3m2!1sen!2sin!4v1731327925359!5m2!1sen!2sin" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"  style={{ border: "0", width: "100%" }}class="embed-responsive-item" ></iframe>
      </div>
      {/* <!-- end google map section --> */}
    </>
  );
};

export default Contact;
